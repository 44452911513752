const steps = [
  {
    element: "#event-type",
    popover: {
      title: "Event - Type",
      description: "This is where you select exactly the type of the event",
      position: "left",
    },
  },
  {
    element: "#event-status",
    popover: {
      title: "Event - Status",
      description: "This is where you select the status of the event",
      position: "left",
    },
  },
  {
    element: "#event-reference",
    popover: {
      title: "Event - Reference",
      description:
        "This is where you select exactly the reference of the event",
      position: "left",
    },
  },
  {
    element: "#event-comment",
    popover: {
      title: "Event - Comment",
      description: "This is where wrtie down any comments for the event",
      position: "left",
    },
  },
  {
    element: "#pick-up",
    popover: {
      title: "Event - Pick Up Location",
      description: "This is where you state the pick up location for the event",
      position: "right",
    },
  },
  {
    element: "#delivery",
    popover: {
      title: "Event - Delivery Location",
      description:
        "This is where you state the delivery location for the event",
      position: "right",
    },
  },
  {
    element: "#submit-menu",
    popover: {
      title: "Event - Submission Date",
      description: "This is where you state the submission date for the event",
      position: "right",
    },
  },
  {
    element: "#resubmit-menu",
    popover: {
      title: "Event - Resubmission Date",
      description:
        "This is where you state the resubmission date for the event",
      position: "right",
    },
  },
  {
    element: "#rejected-menu",
    popover: {
      title: "Event - Rejection Date",
      description: "This is where you state the rejection date for the event",
      position: "right",
    },
  },
  {
    element: "#completed-menu",
    popover: {
      title: "Event - Completed Date",
      description: "This is where you state the completed date for the event",
      position: "right",
    },
  },
];

export default steps;
