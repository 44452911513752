<template>
  <div>
    <v-card flat style="background: transparent !important">
      <v-card-text style="background: transparent !important">
        <v-row>
          <v-col cols="12" sm="3">
            <v-card outlined :loading="loadingCustomEvents" id="events-card">
              <v-card-title class="my-0"> <v-icon left color="grey">event</v-icon> Events
                <v-spacer></v-spacer>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">

                    <v-btn v-on="on" text @click="addCustomEvent">
                      <v-icon>add_circle_outline</v-icon>
                    </v-btn>
                  </template>
                  Add Custom Event
                </v-tooltip></v-card-title>

              <v-card-text class="my-0 py-0">
                <v-list dense>
                  <v-list-item v-if="events.length == 0">
                    <v-list-item-content class="text-center">
                      <span style="color: grey">No available events</span>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-for="event in events" :key="event.id" @click="viewEvent(event)">
                    <v-list-item-action>
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-icon class="mr-2" v-on="on" :color="getStatus(event.status).color">{{
                            getStatus(event.status).icon }}</v-icon>
                        </template>
                        <span>{{ event.status }}</span>
                      </v-tooltip>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ event.title }}
                      </v-list-item-title>
                      <v-list-item-subtitle v-if="event.reference">
                        {{ event.reference }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item v-for="event in customEvents" :key="event.id" @click="editCustomEvent(event)">
                    <v-list-item-action>
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-icon class="mr-2" v-on="on" :color="getStatus(getCustomEventStatus(event.status)).color">{{
                            getStatus(getCustomEventStatus(event.status)).icon }}</v-icon>
                        </template>
                        <span>{{ getCustomEventStatus(event.status) }}</span>
                      </v-tooltip>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ event.shipmentCustomEventType.description }}
                      </v-list-item-title>
                      <v-list-item-subtitle v-if="event.reference">
                        {{ event.reference }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
            <v-card outlined id="mass-actions">
              <v-card-title class="my-0"> <v-icon left color="grey">fact_check</v-icon> Mass Actions </v-card-title>
              <v-card-text class="my-0 py-0">
                <v-list dense>
                  <v-list-item v-if="massActions.length == 0">
                    <v-list-item-content class="text-center">
                      <span style="color: grey">No available actions</span>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-for="action in massActions" :key="action.code" @click="startMassAction(action)">
                    <!-- <v-list-item-action>
                          <v-img v-if="action.image" contain src="../../assets/ppecblogo.png"></v-img>
                        </v-list-item-action> -->
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ action.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle v-if="action.description">
                        {{ action.description }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-progress-circular indeterminate v-show="startingAction" color="primary"></v-progress-circular>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="9">
            <v-data-table id="task-table" @click:row="viewTask" hide-default-footer group-by="locationDocument.name"
              :key="taskUpdateKey" disable-pagination height="58vh" style="cursor: pointer" fixed-header :items="tasks"
              :headers="headers" dense>
              <template v-slot:[`group.header`]="{ items, isOpen, toggle }">
                <th colspan="12">
                  <v-icon @click="toggle" small class="mr-1">{{ isOpen ? 'remove' : 'add' }}
                  </v-icon>
                  <v-chip small class="mx-1">{{ items.length }}</v-chip>
                  <span v-if="items[0].locationDocument.parentTypeDocument">{{
                    items[0].locationDocument.parentTypeDocument.name
                  }}</span> <span v-else>{{
  items[0].locationDocument.name
}}</span>
                </th>
              </template>
              <template v-slot:[`item.locationDocument.integrationType`]="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn icon outlined small v-on="on">
                      <v-icon small>{{ getIntegration(item).icon }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ getIntegration(item).name }}</span>
                </v-tooltip>
              </template>

              <template v-slot:[`item.title`]="{ item }">
                <v-tooltip top v-if="item.inspectionCode">
                  <template v-slot:activator="{ on }">
                    <v-btn icon small v-on="on" class="mr-1">
                      <v-icon small :color="getInspectionColor(item.inspectionStatus)">rule</v-icon>
                    </v-btn>
                  </template>
                  <span v-if="!item.inspectionStatus">Inspection Required</span>
                  <span v-else>Inspection status: {{ item.inspectionStatus }}</span>
                </v-tooltip>
                <span>{{ item.title }}</span>
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <v-chip :key="taskUpdateKey" :color="getTaskStatus(item).color" outlined small><v-icon small left>{{
                  getTaskStatus(item).icon
                }}</v-icon>
                  {{ item.status }}</v-chip>
              </template>
              <template v-slot:[`item.bookingContainer.containerNo`]="{ item }">
                <span v-if="item.bookingContainer && item.bookingContainer.containerNo">{{
                  item.bookingContainer.containerNo
                }}</span>
                <span v-else-if="item.bookingContainer && item.bookingContainer.ctoNo">{{
                  item.bookingContainer.ctoNo
                }}</span>
                <div v-else-if="attachedDocument(item)">
                  <v-tooltip top v-if="attachedDocument(item).quantityType == 'Shipment'">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" icon outlined small class="mx-1" v-on="on">
                        <v-icon small>directions_boat</v-icon>
                      </v-btn>
                    </template>
                    <span style="font-size: 12px; text-transform: capitalize">{{
                      attachedDocument(item).quantityType
                    }}</span>
                  </v-tooltip>

                  <v-tooltip top v-else-if="attachedDocument(item).quantityType == 'Container'">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" icon outlined small class="mx-1" v-on="on" color="orange">
                        <v-icon small>widgets</v-icon>
                      </v-btn>
                    </template>
                    <span style="font-size: 12px; text-transform: capitalize">{{
                      attachedDocument(item).quantityType
                    }}</span>
                  </v-tooltip>

                  <v-tooltip top v-else-if="attachedDocument(item).quantityType == 'CountContainer'">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" icon outlined small class="mx-1" v-on="on" color="blue">
                        <v-icon small>view_module</v-icon>
                      </v-btn>
                    </template>
                    <span style="font-size: 12px; text-transform: capitalize">
                      Per {{ attachedDocument(item).count }} Containers</span>
                  </v-tooltip>
                </div>

              </template>
              <template v-slot:[`item.subtasks`]="{ item }">
                <span style="font-size: 14px" v-if="item.childTasks.length > 0">
                  <b :style="{
                    'font-size': '14px',
                    color:
                      item.childTasks.filter((x) => x.status == 'Complete')
                        .length == item.childTasks.length
                        ? 'green'
                        : 'orange',
                  }">{{
  item.childTasks.filter((x) => x.status == "Complete").length
}}/{{ item.childTasks.length }}
                  </b>
                </span>
              </template>

              <template v-slot:[`item.attachments`]="{ item }">
                <v-chip small v-if="item.taskAttachments.filter(x => x.file).length > 0">
                  {{ item.taskAttachments.filter(x => x.file).length }}
                </v-chip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>

      </v-card-text>
    </v-card>

    <v-dialog v-model="taskModal" width="1200px" persistent :fullscreen="$vuetify.breakpoint.mobile">
      <TaskModal :task="task" :shipment="shipment" :bookings="bookings" @updateDocument="updateDocument"
        :actions="actions" :documents="documents" :documentTypes="documentTypes" @close="(taskModal = false), (task = {})"
        :documentUpdateKey="documentUpdateKey" @update="update" :statusKey="taskUpdateKey" />
    </v-dialog>


    <v-dialog v-model="formPreview" width="600px" :fullscreen="$vuetify.breakpoint.mobile">
      <FormModal :key="formKey" :form="form.form" @close="formPreview = false" @submit="submitForm"
        :skipValidate="false" />
    </v-dialog>

    <v-dialog v-model="eventModal" width="1000px" :fullscreen="$vuetify.breakpoint.mobile">
      <EventModal :event="event" :documents="uploads" @close="eventModal = false" @update="updateEvent" />
    </v-dialog>

    <v-dialog v-model="customEventModal" persistent width="800px" :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-toolbar flat color="transparent"><v-toolbar-title>Create Custom Event</v-toolbar-title> <v-spacer></v-spacer>
          <v-btn id="info-guide" @click="customStartGuide" fab height="4vh" width="4vh">
            <v-icon>help</v-icon>
          </v-btn>
          <v-btn id="event-save" color="primary" icon :loading="savingCustomEvent"
            @click="saveCustomEvent"><v-icon>save</v-icon></v-btn>
          <v-btn text @click="customEventModal = false, customEventItem = {}">X</v-btn></v-toolbar>
        <v-card-text>
          <v-row>
            <v-col>
              <v-autocomplete id="event-type" label="Event Type*" :items="customEventTypes"
                v-model="customEventItem.shipmentCustomEventTypeId" outlined dense clearable item-text="description"
                item-value="id" prepend-icon="format_list_bulleted"></v-autocomplete>
              <v-autocomplete id="event-status" :items="customEventStatuses" v-model="customEventItem.status"
                label="Event Status" outlined dense clearable item-text="name" item-value="value"
                prepend-icon="star_outline"></v-autocomplete>
              <v-text-field id="event-reference" outlined dense v-model="customEventItem.reference"
                placeholder="Reference" prepend-icon="description"></v-text-field>
              <v-textarea id="event-comment" outlined rows="4" v-model="customEventItem.comment" placeholder="Comment"
                prepend-icon="comment" dense></v-textarea>
            </v-col>

            <v-col>
              <div v-if="customEventItem.shipmentCustomEventTypeId === 1">
                <v-autocomplete id="pick-up" label="Pickup Location" :items="organisationAddresses"
                  v-model="customEventItem.pickupAddress" outlined dense clearable item-text="alias" item-value="id"
                  prepend-icon="home_filled"></v-autocomplete>
                <v-autocomplete id="delivery" label="Delivery Location" :items="organisationAddresses"
                  v-model="customEventItem.deliveryAddress" outlined dense clearable item-text="alias" item-value="id"
                  prepend-icon="home"></v-autocomplete>
              </div>
              <div id="submit-menu">
                <v-menu v-model="sumbittedMenu" :close-on-content-click="false" :nudge-right="40"
                  transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="customEventItem.submittedAt" label="Submitted Date" prepend-icon="event"
                      readonly v-bind="attrs" v-on="on" outlined dense></v-text-field>
                  </template>
                  <v-date-picker v-model="customEventItem.submittedAt" @input="sumbittedMenu = false"></v-date-picker>
                </v-menu>
              </div>
              <div id="resubmit-menu">
                <v-menu v-model="resumbittedMenu" :close-on-content-click="false" :nudge-right="40"
                  transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="customEventItem.resubmittedAt" label="Resubmitted Date" prepend-icon="event"
                      readonly v-bind="attrs" v-on="on" outlined dense></v-text-field>
                  </template>
                  <v-date-picker v-model="customEventItem.resubmittedAt" @input="resumbittedMenu = false"></v-date-picker>
                </v-menu>
              </div>
              <div id="rejected-menu">
                <v-menu v-model="rejectedMenu" :close-on-content-click="false" :nudge-right="40"
                  transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="customEventItem.rejectedAt" label="Rejected Date" prepend-icon="event" readonly
                      v-bind="attrs" v-on="on" outlined dense></v-text-field>
                  </template>
                  <v-date-picker v-model="customEventItem.rejectedAt" @input="rejectedMenu = false"></v-date-picker>
                </v-menu>
              </div>
              <div id="completed-menu">
                <v-menu v-model="completedMenu" :close-on-content-click="false" :nudge-right="40"
                  transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="customEventItem.completedAt" label="Completed Date" prepend-icon="event"
                      readonly v-bind="attrs" v-on="on" outlined dense></v-text-field>
                  </template>
                  <v-date-picker v-model="customEventItem.completedAt" @input="completedMenu = false"></v-date-picker>
                </v-menu>
              </div>
            </v-col>
          </v-row>
          <v-row class="mt-2" justify="center">
            <v-btn @click="deleteCustomEvent()" :loading="deletingCustomEvent" v-if="customEventItem.id" color="red"
              style="text-transform: none"><v-icon>delete</v-icon> Delete</v-btn>

          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="infoStart" max-width="31vw">
      <v-card>
        <v-btn @click="infoStartGuide" icon style="position: absolute; right: 0; margin: 0.6vh 1vh 0 0;"><v-icon
            color="primary" size="30">close</v-icon></v-btn>
        <v-card-title class="d-flex justify-center">
          FRIKO Welcome to Task & Actions!
        </v-card-title>
        <v-card-text>
          <span>
            <p>FRIKO</p>
            <p>FRIKO
              <br>• Map out routes for transporters
              <br>• Add GeoJSON data to locations used determine if a vehicle is within a certain area
              <br>• Calculate accurate waiting times at loading points
              <br>• Calculate distances for billing purposes
              <br>• Pin-point locations for the purpose of reporting and analysis
              <br>• Map out farms that aren't found on Google Maps
            </p>
            If you would like learn how to load POI's into the system, click the button below to start the
            tutorial.
          </span>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn @click="startGuide" color="edit" class="mb-3">Start!</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="csStart" max-width="31vw">
      <v-card>
        <v-btn @click="customStartGuide" icon style="position: absolute; right: 0; margin: 0.6vh 1vh 0 0;"><v-icon
            color="primary" size="30">close</v-icon></v-btn>
        <v-card-title class="d-flex justify-center">
          FRIKO Welcome to Task & Actions - Custom Event!
        </v-card-title>
        <v-card-text>
          <span>
            <p>FRIKO</p>
            <p>FRIKO
              <br>• Map out routes for transporters
              <br>• Add GeoJSON data to locations used determine if a vehicle is within a certain area
              <br>• Calculate accurate waiting times at loading points
              <br>• Calculate distances for billing purposes
              <br>• Pin-point locations for the purpose of reporting and analysis
              <br>• Map out farms that aren't found on Google Maps
            </p>
            If you would like learn how to load POI's into the system, click the button below to start the
            tutorial.
          </span>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn @click="startCustomGuide" color="edit" class="mb-3">Start!</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Help Guide/ Tutorial -->
    <v-btn id="info-guide" @click="infoStartGuide" fab height="4vh" width="4vh"
      style="position: absolute; z-index: 5; right: 1vw; top: 1vh">
      <v-icon>help</v-icon>
    </v-btn>

  </div>
</template>
<script>
import EventModal from "@/components/SystemComponents/EventModal.vue";

import FormModal from "@/components/SystemComponents/FormModal.vue";
import TaskModal from "../SystemComponents/TaskModal.vue";

import Driver from 'driver.js'
import 'driver.js/dist/driver.min.css'
import taskSteps from './Steps/taskSteps'
import csSteps from './Steps/taskCustomEventSteps'

export default {
  props: ["tasks", "actions", "events", "shipment", "taskUpdateKey", "documents", "documentTypes", "documentUpdateKey", "bookings", "uploads"],
  components: {
    EventModal,
    FormModal,
    TaskModal
  },
  watch: {
    tasks(val) {
      if (this.task && this.task.id) {
        this.task = val.find(x => x.id == this.task.id)
      }
      this.getMassActions(this.shipment.id)
    },
    documents(val) {
      console.log('Task Documents', val)
    },
    'shipment.id': {
      immediate: true,
      handler(val) {
        if (val)
          this.getMassActions(val)
      }
    }
  },
  data: () => ({
    event: {},
    eventModal: false,
    customEventItem: {},
    customEvents: [],
    customEventModal: false,
    customEventTypes: [],
    loadingCustomEvents: false,
    savingCustomEvent: false,
    deletingCustomEvent: false,
    sumbittedMenu: false,
    resumbittedMenu: false,
    rejectedMenu: false,
    completedMenu: false,
    organisationAddresses: [],
    form: {},
    formKey: 0,
    formPreview: false,
    headers: [
      {
        text: "Status",
        value: "status",
        align: "center",
        width: "150px"
      },

      {
        text: "Title",
        value: "title",
        align: "left",
      },
      {
        text: "Container",
        value: "bookingContainer.containerNo",
        align: "center",
      },
      {
        text: "Integration Type",
        value: "locationDocument.integrationType",
        align: "center",
      },
      {
        text: "Subtasks",
        value: "subtasks",
        align: "center",
        sortable: false,
      },
      {
        text: "Attachments",
        value: "attachments",
        align: "center",
        sortable: false,
      },
    ],
    customEventStatuses: [
      { name: "Pending", value: "PENDING" },
      { name: "Submitted", value: "SUBMITTED" },
      { name: "Resubmitted", value: "RESUBMITTED" },
      { name: "Rejected", value: "REJECTED" },
      { name: "Completed", value: "COMPLETED" },
    ],
    integrationTypes: [
      { name: "Manual Template", value: "manual" },
      { name: "REST API", value: "restapi" },
      { name: "SOAP API", value: "soap" },
      { name: "EDIFACT", value: "edifact" },
    ],
    massActions: [],
    startingAction: false,
    statusKey: 2000,
    task: {},
    taskModal: false,
    infoStart: false,
    csStart: false,
  }),
  async mounted() {
    this.getCustomEvents()
    this.getOrganisationAddresses()
    this.driver = new Driver({
      animate: false
    })
  },
  methods: {
    attachedDocument(task) {
      let result = null
      let documentId = task.parentShipmentDocumentId ?? task.shipmentDocumentTypeId
      if (documentId) {
        result = this.documentTypes.find(x => x.id == documentId)
      }
      return result
    },
    getIntegration(document) {
      let integrationDocument = document;
      switch (integrationDocument.locationDocument.integrationType) {
        case "restapi":
          return { name: "API", icon: "api" };
        case "manual":
          return { name: "Manual Template", icon: "description" };
        default:
          return {
            name: this.integrationTypes.find(
              (x) =>
                x.value == integrationDocument.locationDocument.integrationType
            ).name,
            icon: "webhook",
          };
      }
    },
    getInspectionColor(status) {
      switch (status) {
        case null:
          return 'orange'
        case 'New':
          return 'blue'
        case 'Resubmitted':
          return 'blue'
        case 'Approved':
          return 'green'
        case 'Rejected':
          return 'red'
      }
    },
    async getMassActions(id) {
      this.massActions = await this.$API.getShipmentMassActions(id)
    },
    getStatus(status) {
      switch (status) {
        case "Not started":
          return { name: status, icon: "schedule", color: "grey" };
        case "Pending":
          return { name: status, icon: "pending", color: "orange" };
        case "In Progress":
          return { name: status, icon: "sync", color: "blue" };
        case "Delivered":
          return { name: status, icon: "task_alt", color: "green" };
        case "In Transit":
          return { name: status, icon: "local_shipping", color: "blue" };
        case "Complete":
          return { name: status, icon: "task_alt", color: "green" };
        case "Completed":
          return { name: status, icon: "task_alt", color: "green" };
        case "File Generated":
          return { name: status, icon: "task", color: "orange" };
        case "Success":
          return { name: status, icon: "task_alt", color: "green" };
        case "Cancelled":
          return { name: status, icon: "cancel", color: "grey" };
        case "Draft":
          return { name: status, icon: "edit_note", color: "orange" };
        case "Error":
          return { name: status, icon: "error", color: "red" };
        case "Failed":
          return { name: status, icon: "cancel", color: "red" };
        case "Rejected":
          return { name: status, icon: "cancel", color: "red" };
        case "Submitted":
          return { name: status, icon: "mark_email_read", color: "blue" };
        case "Resubmitted":
          return { name: status, icon: "mark_email_read", color: "orange" };
        case "Not Required":
          return { name: status, icon: "close", color: "grey" };
        case "Approved":
          return { name: status, icon: "fact_check", color: "green" };
        default:
          return {
            icon: "info",
            color: "grey",
            name: status,
          };
      }
    },
    getTaskStatus(task) {
      switch (task.status) {
        case "New":
          return { name: task.status, icon: "grade", color: "orange" };
        case "Not started":
          return { name: task.status, icon: "schedule", color: "grey" };
        case "Pending":
          return { name: task.status, icon: "pending", color: "orange" };
        case "Resubmitted":
          return { name: task.status, icon: "sync", color: "orange" };
        case "In Progress":
          return { name: task.status, icon: "sync", color: "blue" };
        case "Complete":
          return { name: task.status, icon: "task_alt", color: "green" };
        case "File Generated":
          return { name: task.status, icon: "task", color: "orange" };
        case "Success":
          return { name: task.status, icon: "task_alt", color: "green" };
        case "Cancelled":
          return { name: task.status, icon: "cancel", color: "grey" };
        case "Not Created":
          return { name: task.status, icon: "error", color: "red" };
        case "Rejected":
          return { name: task.status, icon: "cancel", color: "red" };
        case "Revoked":
          return { name: task.status, icon: "error", color: "red" };
        case "Draft":
          return { name: task.status, icon: "edit_note", color: "orange" };
        case "Error":
          return { name: task.status, icon: "error", color: "red" };
        case "Failed":
          return { name: task.status, icon: "cancel", color: "red" };
        case "Submitted":
          return { name: task.status, icon: "mark_email_read", color: "blue" };
        case "Not Required":
          return { name: task.status, icon: "close", color: "grey" };
        case "Approved":
          return { name: task.status, icon: "fact_check", color: "green" };
        default:
          return {
            icon: "info",
            color: "grey",
            name: task.status,
          };
      }
    },
    updateDocument(doc) {
      this.$emit('updateDocument', doc)
    },
    update(doc) {
      console.log('Updating task', doc)
      this.task = doc
      let findTask = this.tasks.findIndex(x => x.id == doc.id)
      console.log('Updating task index', findTask)

      if (findTask > -1) {
        this.tasks[findTask] = doc
        this.statusKey++
      }
      this.$emit('update', doc)
    },
    async startMassAction(action) {
      this.startingAction = true
      await this.$API.startMassAction(this.shipment.id, action.code)
      let index = this.massActions.indexOf(action)
      this.massActions.splice(index, 1)
      this.$message.success('Successfully started tasks')
      this.startingAction = false
    },

    async submitForm(response) {
      this.formPreview = false;
      this.form.response = response;
      this.form.userId = this.$store.state.user.id;
      this.form.user = this.$store.state.user;
      this.form.status = "Complete";
      await this.$API.submitShipmentActions(this.form);
      this.form = {};
    },
    async getOrganisationAddresses() {
      this.organisationAddresses = await this.$API.getOrganisationAddress()
    },
    async getCustomEvents() {
      this.loadingCustomEvents = true
      this.customEvents = await this.$API.getShipmentCustomEvents(this.shipment.id)
      this.customEventTypes = await this.$API.getCustomEventTypes()
      this.loadingCustomEvents = false
    },
    async saveCustomEvent() {
      this.savingCustomEvent = true
      this.customEventItem.shipmentId = this.shipment.id
      this.customEventItem.createdBy = this.$store.state.user.id
      if (this.customEventItem && this.customEventItem.id) {
        this.customEventItem = await this.$API.updateShipmentCustomEvent(this.customEventItem, this.customEventItem.id)
      } else {
        this.customEventItem = await this.$API.createShipmentCustomEvent(this.customEventItem)
      }
      this.getCustomEvents()
      this.savingCustomEvent = false
      this.customEventItem = {}
      this.customEventModal = false
    },
    async deleteCustomEvent() {
      this.deletingCustomEvent = true
      this.$confirm(
        "Are you sure you want to delete this event?",
        "Confirm",
        {
          center: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "plain",
        }
      )
        .then(async () => {
          this.customEventItem.isDeleted = true;
          this.customEventItem.isActive = false;
          await this.$API.updateShipmentCustomEvent(this.customEventItem, this.customEventItem.id);
          this.$message({
            type: "success",
            message: "Successfully deleted!",
          });
          this.getCustomEvents()
          this.customEventModal = false
          this.customEventItem = {}
          this.deletingCustomEvent = false
        })
        .catch(() => {
          this.deletingCustomEvent = false
          this.$message({
            type: "info",
            message: "Delete canceled",
          });
        });
    },
    getCustomEventStatus(value) {
      return this.customEventStatuses.find(obj => obj.value === value)?.name;
    },
    async editCustomEvent(event) {
      this.customEventItem = event
      this.customEventModal = true
    },
    addCustomEvent() {
      this.customEventModal = true
    },
    updateEvent(event) {
      this.event = event;
      this.$emit("updateEvent", event);
    },
    viewEvent(event) {
      this.event = event;
      this.eventModal = true;
    },
    async viewTask(task) {
      this.task = task;
      this.taskModal = true;
      // try {
      //   let link = await this.$API.getSecureLink({url: 'https://secure.cdn.loglive.io/dev/task/604/request_1680418570321.json'})
      // console.log('Link', link)
      // if(link){
      //   let result = await this.$API.downloadSecureLink(link)
      //   console.log(result)
      // }
      // } catch(e){
      //   console.log(e)
      // }
    },
    infoStartGuide() {
      if (this.infoStart) {
        this.infoStart = false;
      } else {
        this.infoStart = true;
      }
    },
    startGuide(e) {
      this.infoStart = false
      e.stopPropagation();
      this.driver.defineSteps(taskSteps)
      this.driver.start()
    },
    customStartGuide() {
      if (this.csStart) {
        this.csStart = false;
      } else {
        this.csStart = true;
      }
    },
    startCustomGuide(e) {
      this.csStart = false
      e.stopPropagation();
      this.driver.defineSteps(csSteps)
      this.driver.start()
    },
  },
};
</script>
