const steps = [
  {
    element: "#events-card",
    popover: {
      title: "Events",
      description:
        "Displays a list of events as well as allows you to add or edit these events",
      position: "right",
    },
  },
  {
    element: "#mass-actions",
    popover: {
      title: "Mass Actions",
      description:
        "Displays all the relevant information regarding mass actions",
      position: "right",
    },
  },
  {
    element: "#task-table",
    popover: {
      title: "Task & Actions Table",
      description:
        "Displays all the relevant information regarding task & actions in a data table format",
      position: "top",
    },
  },
];

export default steps;
